.image {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.screen {
    /*
    border: solid 3px white;
    */
    max-height: 300px;
}
